import Service from "../Service";

const resource = "FrzChamberPalletLosses/";

export default {
    pagination(dtr, requestID){
        return Service.post(resource + "pagination", dtr,{
            params:{requestID: requestID},
        });
    },
    listPalletGroupID(dtr, requestID){
        return Service.post(resource + "listPalletGroupID", dtr,{
            params:{requestID: requestID},
        });
    },
    

}