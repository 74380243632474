<template>
    <div>
      <s-crud 
        title="Detalle de Mermas" 
        :filter="filter" height="150"        
        :config="configCertificate"        
        ref="scrudcertification"
      >
        <template v-slot:filter>
          <v-container>
            <v-row justify="center">
              <v-col cols="auto">
                <s-date
                  label="Fecha Inicio"
                  v-model="filter.DateBegin"
                  
                ></s-date>
              </v-col>
              <v-col cols="auto">
                <s-date
                  label="Fecha Fin"
                  v-model="filter.DateEnd"
                  
                ></s-date>
              </v-col>
              <v-col cols="auto">
                <s-text
                  label="N° Pallet Ticket"
                  v-model="filter.PalletGroupID"  
                  clearable    
                ></s-text>
              </v-col>
              <v-col  cols="auto" >
                <s-select-client
                    clearable
                    label="Cliente(es):"
                    v-model="filter.CumID"
                    ref="txtCumID"
                    full 
                    >
                </s-select-client>      
              </v-col>
              <v-col cols="auto">                              
                <s-select-definition
                    clearable
                    label="Tipo corte"
                    :def="1429"
                    v-model="filter.TypeCut"
                    
                ></s-select-definition>    
              </v-col>
              <v-col cols="auto">
                <s-select-definition
                    clearable
                    label="Tipo Empaque"
                    v-model="filter.TypePacking"
                    :def="1435"
                    
                ></s-select-definition>
              </v-col>
            </v-row>
          </v-container>
        </template>
      
        <template v-slot:PalletGroupID="{ row }">
          <v-chip small :style="[{backgroundColor: genColor()}]">
            {{ row.PalletGroupID }}
          </v-chip>
        </template>
      </s-crud>
    </div>
  </template>
<script>

  // import _sFrzChamberDetailLosseDtlService from "@/services/FrozenProduction/FrzChamberDetailLosseDtlService.js";
  import _sFrzChamberDetailLosseService from "@/services/FrozenProduction/FrzChamberDetailLosseService.js";
  import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";

  
  export default {
    components: {SSelectClient},
    props: {
    
    },
  
    data: () => ({
        filter: {},
        configCertificate: {
            model: {
                // CplID: "int",
                PacID: "int",
                CplID: "ID",
                PalletGroupID: "",
            },
            service: _sFrzChamberDetailLosseService,
            headers: [
                { text: "ID", value: "CplID" },
                { text: "Fecha", value: "DateIncome" },
                { text: "Hora", value: "HourBegin" },
                { text: "Ubicacion", value: "PalletLocation", width: 150 },
                { text: "N° Pallet", value: "PalletGroupID"},
                { text: "ID Proveedor", value: "RcfIDs"},
                { text: "ID Trazabilidad", value: "TraceabilityID"},
                { text: "Cliente", value: "CumNombre"},
                { text: "Marca", value: "CbdBrans", width: 100 },
                { text: "Cultivo", value: "TypeCultiveName"},
                { text: "Variedad", value: "VrtDescription"},
                { text: "Tipo de Corte", value: "TypeCutName"},
                { text: "Presentación", value: "TypePackingName"},
                { text: "N° Cajas Descarte", value: "NumberBoxesLosses" },
                { text: "Peso descarte(Kg)", value: "WeightNetBoxesLosses" },
                { text: "Condicion", value: "IsObservedName" },
                { text: "Tipo Congelamiento", value: "CecDescription" },
                { text: "Observación", value: "Observations" },                
                
                // { text: "Tipo Cultivo", value: "TypeCropName", width: 160 },
            ],
        },
        DateBegin: '',
        DateEnd : '',
        itemsPalletGroupID : [],

    }),
    methods: {
        
  
        
        genColor(){
            let rojo = Math.floor(Math.random() * 256)
            let verde = Math.floor(Math.random() * 256)
            let azul = Math.floor(Math.random() * 256)

            return `rgb(${rojo}, ${verde}, ${azul})`
        },
        palletGroupID(){
          
          if ( this.DateBegin == null || this.DateEnd == null) {
                    return;
                }
          let data = {
            DateBegin : this.DateBegin,
            DateEnd	  : this.DateEnd,
          }
          _sFrzChamberDetailLosseService.listPalletGroupID(data,this.$fun.getUserID())
          .then(r =>{
            if(r.status == 200)
            {
              this.itemsPalletGroupID = r.data;
            }
          });
        },
    },
    watch: {
   
      parentID() {
        
        this.palletGroupID();
      },
     
      
    },
    created() {
     
    },
  };
  </script>
  