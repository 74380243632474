<template>
    <div>
      <v-row>       
        <v-col lg="12">
          <losses-main></losses-main> 
        </v-col>
      </v-row>
    </div>
  </template>
<script>
  
  import LossesMain from "./FrzChamberLosses.vue";

  
  export default {
    components: {LossesMain},//LossesHeader, LossesDetail
    data(){
      return {
        parentID: 0,
        PacID: 0,
        farmLotID: 0,
        lots: []
      };
    },
    methods: {
      rowSelectedFarms(rows) {
        this.parentID = rows[0].PacID;
      },
      getFarmLotID(farmLotID){
        this.farmLotID = farmLotID;
      }
    },
  };
  </script>
  